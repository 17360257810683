<template>
  <div class="container">
    <div class="grid grid3">
      <value-card 
        background-class="bg-info"
        label="Aantal polls"
        :value="polls.length"
        font-awesome-icon-name="poll-h"
      />

      <value-card 
        background-class="bg-secondary"
        label="Totaal aantal stemmen"
        :value="totalVoteCount"
        font-awesome-icon-name="vote-yea"
      />

      <value-card 
        background-class="bg-success"
        label="Gemiddeld-stem-percentage"
        :value="stats.pollVotePercentage.toFixed(2)"
        font-awesome-icon-name="comment-dots"
        show-percentage-symbol
      />
    </div>
  </div>
  
  <div class="grid grid4">
    <poll-card
      v-for="poll in polls"
      :key="poll.id"
      :poll="poll"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getAll as getAllPolls } from '@/api/providers/poll';
import { getPollsStatistics } from '@/api/providers/statistics';

import PollCard from '@/components/Poll/PollCard.vue';
import ValueCard from '@/components/Dashboard/ValueCard.vue';

export default {
  components: {
    PollCard,
    ValueCard
  },

  data() {
    return {
      polls: [],
      stats: {
        pollVotePercentage: 0.0
      }
    };
  },

  computed: {
    totalVoteCount() {
      let count = 0;

      for(const poll of this.polls) {

        count += poll.totalVotesCount;
      }

      return count;
    }
  },

  async beforeMount() {
    this.addAction({
        handler: () => this.$router.push('/polls/new'),
        icon: 'add',
        text: 'Nieuw',
      });
    this.getPolls();
    this.stats = await getPollsStatistics()
  },

  methods: {
    ...mapActions('appHeader', [
      'addAction',
    ]),
    async getPolls() {
      this.polls = await getAllPolls(); 
    }
  }
}
</script>